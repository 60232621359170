jQuery(document).ready(function ($) {
  let birthDate = {
    day: "",
    month: "",
    year: "",
  };

  const form = [$("#input-name"), $("#input-surname"), $("#input-patronymic")];

  $("#input-phone").inputmask({ mask: "+\\9\\98 (99) 999-99-99" });

  $("#input-phone").on("change", function () {
    var tel = $(this).val();
    $("#phone_number").val(`+${tel.replace(/[^0-9]/g, "")}`);
  });

  form.forEach((item) => {
    item.on("blur", function () {
      const value = item.val();

      item.attr("value", value);
      if (value.length < 3 || /[0-9]/.test(value)) {
        item.addClass("invalid");
      } else {
        item.removeClass("invalid");
      }
    });
  });

  $("#input-day").on("change", function () {
    var day = $(this).val();

    birthDate.day = day;
    $("#birth_date").val(
      birthDate.year + "-" + birthDate.month + "-" + birthDate.day
    );
  });

  $("#input-month").on("change", function () {
    var month = $(this).val();

    birthDate.month = month;
    $("#birth_date").val(
      birthDate.year + "-" + birthDate.month + "-" + birthDate.day
    );
  });

  $("#input-year").on("change", function () {
    var year = $(this).val();

    birthDate.year = year;
    $("#birth_date").val(
      birthDate.year + "-" + birthDate.month + "-" + birthDate.day
    );
  });
});
