document.addEventListener("DOMContentLoaded", function () {
  ymaps.ready(init);
  const types = [
    {
      id: 1,
      title: "Кафе",
      icon: "icon-cafe.png",
    },
    {
      id: 2,
      title: "Бар",
      icon: "icon-bar.png",
    },
    {
      id: 3,
      title: "Ресторан",
      icon: "icon-restaurant.png",
    },
    {
      id: 4,
      title: "Гостиница",
      icon: "icon-hotel.png",
    },
    {
      id: 5,
      title: "Зона отдыха",
      icon: "icon-zona-otdyha.png",
    },
    {
      id: 6,
      title: "Касса авиабилетов",
      icon: "icon-kassa-aviabiletov.png",
    },
    {
      id: 7,
      title: "Кинотеатр",
      icon: "icon-kinoteatr.png",
    },
    {
      id: 8,
      title: "Театр",
      icon: "icon-teatr.png",
    },
    {
      id: 9,
      title: "SPA",
      icon: "icon-spa.png",
    },
    {
      id: 10,
      title: "Парк аттракционов",
      icon: "icon-park-atrakcionov.png",
    },
    {
      id: 11,
      title: "Тренажерный зал",
      icon: "icon-trenajorniy-zal.png",
    },
    {
      id: 12,
      title: "Школа",
      icon: "icon-shkola.png",
    },
    {
      id: 13,
      title: "Детский сад",
      icon: "icon-detskiy-sad.png",
    },
    {
      id: 14,
      title: "Культурный центр",
      icon: "icon-kulturny-center.png",
    },
    {
      id: 15,
      title: "Учебный центр",
      icon: "icon-uchebniy-center.png",
    },
    {
      id: 16,
      title: "Коворкинг студия",
      icon: "icon-coworking.png",
    },
    {
      id: 17,
      title: "Клиника",
      icon: "icon-klinika.png",
    },
    {
      id: 18,
      title: "Аптека",
      icon: "icon-apteka.png",
    },
    {
      id: 19,
      title: "Магазин",
      icon: "icon-magazin.png",
    },
    {
      id: 20,
      title: "Супермаркет",
      icon: "icon-market.png",
    },
    {
      id: 21,
      title: "Торговый центр",
      icon: "icon-torgoviy-center.png",
    },
    {
      id: 22,
      title: "Автозаправочная станция",
      icon: "icon-azs.png",
    },
    {
      id: 23,
      title: "Клиннинг центры",
      icon: "icon-cleaning.png",
    },
    {
      id: 24,
      title: "Салоны красоты",
      icon: "icon-salon-krasoti.png",
    },
    {
      id: 25,
      title: "Парикмахерская",
      icon: "icon-parikmaxerskaya.png",
    },
    {
      id: 26,
      title: "Химчистка",
      icon: "icon-ximchistka.png",
    },
    {
      id: 27,
      title: "Фабрика",
      icon: "icon-fabrika.png",
    },
    {
      id: 28,
      title: "Ферма",
      icon: "icon-ferma.png",
    },
    {
      id: 29,
      title: "Завод",
      icon: "icon-zavod.png",
    },
    {
      id: 30,
      title: "Цветочный магазин",
      icon: "icon-cvetochniy-magazin.png",
    },
    {
      id: 31,
      title: "Кондитерская",
      icon: "icon-konditerskaya.png",
    },
    {
      id: 32,
      title: "Ателье",
      icon: "icon-atelye.png",
    },
  ];
  // Заготовка для метки
  const point = {
    name: "",
    latitude: "",
    longitude: "",
    address: "",
    text: "",
    type: "",
    icon: "",
  };

  // Заполнение название и описания
  let inputs = document.querySelectorAll(".map__input");
  inputs.forEach((item) => {
    item.addEventListener("input", function (event) {
      if (event.target.id === "pointName") {
        point.name = event.target.value;
      }
      if (event.target.id === "pointText") {
        point.text = event.target.value;
      }
      event.target.style.borderColor = "rgba(0,53,156,.3)";
      console.log(point);
    });

    item.addEventListener("blur", function (event) {
      console.log(event.target.value.length);
      if (event.target.value.length === 0) {
        event.target.style.borderColor = "red";
      } else {
        event.target.style.borderColor = "rgba(0,53,156,.3)";
      }
    });
  });

  // Выбор типа компании

  pointType.addEventListener("change", function (event) {
    point.type = event.target.value;
    point.icon = types[event.target.value].icon;
    console.log(event.target.value);
    console.log(point);
    mapWrapper.style.display = "block";
  });

  function init() {
    let center = [41.311153, 69.279729];

    let map = new ymaps.Map(
      "map",
      {
        center: center,
        zoom: 12,
      },
      {
        searchControlProvider: "yandex#search",
      }
    );

    // let mapList = new ymaps.Map(
    //   "mapList",
    //   {
    //     center: center,
    //     zoom: 12,
    //   },
    //   {
    //     searchControlProvider: "yandex#search",
    //   }
    // );

    let searchControl = map.controls.get("searchControl");

    searchControl.events.add("resultselect", function () {
      let coords = searchControl.getResultsArray()[0].geometry.getCoordinates();
      point.latitude = String(coords[0]);
      point.longitude = String(coords[1]);
      // console.log(coords);
      ymaps.geocode(coords).then(function (res) {
        let data = res.geoObjects.get(0).properties.getAll();
        // console.log(data.text);
        point.address = data.text;
        let newBalloon = new ymaps.Placemark(
          coords,
          {
            balloonContentHeader: `${point.name}`,
            balloonContentBody: `${point.text}`,
            hintContent: `${point.name}`,
            preset: "islands#dotIcon",
            iconColor: "red",
          },
          {
            draggable: true,
            iconLayout: "default#image",
            iconImageSize: [75, 75],
            iconImageHref:
              "../../assets/images/icons/" + types[point.type].icon,
          }
        );

        // Добавляем метку на карту
        map.geoObjects.add(newBalloon);

        // Отслеживаем передвижение метки
        newBalloon.events.add("dragend", function (e) {
          let cord = e.get("target").geometry.getCoordinates();
          point.latitude = String(cord[0]);
          point.longitude = String(cord[1]);
          ymaps.geocode(cord).then(function (res) {
            let data = res.geoObjects.get(0).properties.getAll();
            // console.log(data.text);
            point.address = data.text;
          });
          console.log(point);
        });
      });
    });

    // Удалить данные о метке
    pointDelete.addEventListener("click", function () {
      mapForm.reset();
      map.geoObjects.removeAll();
      mapWrapper.style.display = "none";
    });

    mapForm.addEventListener("submit", async function (event) {
      event.preventDefault();
      console.log(point);
      try {
        const response = await fetch("https://api.ancc.uz/api/coordinate", {
          method: "POST",
          body: JSON.stringify(point),
        });
        const data = await response.json();
        console.log("Добавили ", data);
        mapForm.reset();
        success.style.top = "0px";
        setTimeout(function () {
          success.style.top = "-100px";
        }, 5000);
      } catch (error) {
        console.log("Ошибка при добавлении метки ", error);
        console.log("Отправляемый обьект ", point);
      }
    });
  }
});
