jQuery(document).ready(function ($) {
  $("#requestForm").on("submit", function (event) {
    event.preventDefault();
    var $form = $(this);
    // console.log($form);
    const result = {
      gender: 1,
      middle_name: $form.find("#input-patronymic").val(),
      last_name: $form.find("#input-surname").val() || "",
      first_name: $form.find("#input-name").val() || "",
      birth_date: $form.find("#birth_date").val() || "",
      phone: $form.find("#phone_number").val() || "",
      // password: $form.find("#input-password").val() || "",
      // occupation: $form.find("#input-occupation").val() || "",
    };

    if (result.phone.length === 13) {
      $.ajax({
        url: "https://api.ancc.uz/api/contact",
        type: "POST",
        data: JSON.stringify(result),
        accept: "application/json",
        dataType: "json",
        success: function () {
          $("#requestForm")[0].reset();
          $("#input-name").attr("value", "");
          $("#input-surname").attr("value", "");
          $("#input-patronymic").attr("value", "");

          $("#success").css("top", 0);
          setTimeout(function () {
            $("#success").css("top", -100);
          }, 8000);
        },
      });
    } else {
      $("#input-phone").focus();
    }
  });
});
