jQuery(document).ready(function ($) {
  $("#threeForm").on("submit", function (event) {
    event.preventDefault();
    var $form = $(this);
    // console.log($form);
    console.log($form);
    const result = {
      middle_name: $form.find("#input-patronymic").val(),
      last_name: $form.find("#input-surname").val() || "",
      first_name: $form.find("#input-name").val() || "",
      birth_date: $form.find("#birth_date").val() || "",
      gender: $form.find("#input-gender").val() || "",
      live: $form.find("#input-live").val() || "",
      photo: $form.find("#input-photo").val() || "",
    };

    console.log(result);
    // $.ajax({
    //   url: "https://api.ancc.uz/api/contact",
    //   type: "POST",
    //   data: JSON.stringify(result),
    //   accept: "application/json",
    //   dataType: "json",
    //   success: function (msg) {
    //     $("#requestForm")[0].reset();
    //     $("#success").css("top", 0);
    //     setTimeout(function () {
    //       $("#success").css("top", -100);
    //     }, 8000);
    //   },
    // });
  });
});
